<template>
  <div>
        <div class="w-full my-2 lg:my-1 font-bold text-base text-secondary flex justify-start items-start lg:items-center cursor-pointer" @click="accorEvent">
            <span class="lg:text-lg text-base">+</span>
            <h3 v-if="lang == 'id'" class="ml-2">{{ investData.title }}</h3>
            <h3 v-else class="ml-2">{{ investData.title_eng }}</h3>
        </div>
        <p v-if="lang == 'id'" v-show="this.seen" class="ml-4 lg:pl-4 px-4 my-2 text-justify text-base border-l border-gray-400">{{ investData.content }}</p>
        <p v-else v-show="this.seen" class="ml-4 lg:pl-4 px-4 my-2 text-justify text-base border-l border-gray-400">{{ investData.content_eng }}</p>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: [
        'investData'
    ],
    data() {
        return {
            seen: false
        }
    },
    computed: {
        ...mapState({
            lang: state => state.home.lang ? state.home.lang :'id'
        }),
    },
    methods: {
        accorEvent() {
            // console.log(i);
            this.seen = !this.seen
        }
    }
}
</script>

<style>

</style>
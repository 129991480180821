<template>
  <div class="px-2 lg:px-0">
      <p class="mb-8 text-justify" v-html="hubinvestData.dividend"></p>
      <div>

      </div>
  </div>
</template>

<script>
export default {
  props:[
      'hubinvestData',
      'lang'
  ],
}
</script>

<style>

</style>
<template>
    <div class="kinerja">
        <div class="px-2 lg:px-0">
            <div>
                <div v-for="data, val in getDataTables" class="mb-2 text-2xl font-semibold text-grays-300">
                    {{ val }}
                    <AnnualCard :data="data" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import AnnualCard from './AnnualCard.vue';
import EventBus from '@/eventBus'

export default {
    components: {
        AnnualCard
    },
    data() {
        return {
            getDataTables: "",
           
            yearList: [],
            whichYear: ""
        }
    },
    mounted() {
        axios
        .get('https://api.dharmagroup.co.id/api/report/datatable/performance?perpage=100&format=year', {
            'headers': { 'Authorization': 'Bearer 71063a7eab248181eafe665bf4ae346f-sFqsGThLIgEL9Kvjo0NZInsTou2NMoexYTnPwVjXAgV1K2scDuW58jBBy53'} })
        .then((res) => {
            this.getDataTables = res.data.data   
            let rev = this.dict_reverse(res.data.data)
        })

        EventBus.$on('DATA_PUBLISHED', (payload) => {
            this.updateData(payload)
        })
    },
    methods: {
        openItem(i, e) {
            window.open(i.filename);
        },
        updateData(payload) {
            this.whichYear = payload
        },
        dict_reverse(obj) {
            const ordered = Object.keys(this.getDataTable).sort().reverse((obj, key) => { 
                obj[key] = this.getDataTable[key]; 
                return obj;
            }, 
            {}
            );

            this.yearList = ordered
        }
    },
    computed: {
        sortData() {
            return this.getDataTable[this.whichYear]
        }
        
    }
}
</script>

<style lang="scss">
    .kinerja {
        .material-table{
            position: relative;
            padding-top: 72px !important;

            .table-header{
                border: none;
                width: 78px;
                position: absolute;
                right: -11px;
                top: 27px;
                z-index: 10;
                height: 48px;
            }
        }

        table {
            @apply w-full mt-5;

            thead{
                background-color: #312963;

                th{
                    @apply text-grays-50 text-base font-bold
                }
                .sorting-desc, .sorting-asc{
                    @apply text-primary
                }
            }

           
        }

        #search-input-container{
            @apply absolute  my-2 border-none w-80 -right-3 top-5 mr-0;
            

            input{
                @apply  shadow rounded   p-3 w-full pr-10 outline-none;
                
            }

            #search-input{
                border:1px solid #212121 !important;
                height: 42px !important;
            }
        }

        
    }
</style>
<template>
  <div class="px-2 lg:px-0">
      <div>
          <div v-for="data, val in getDataTable" class="mb-2 text-2xl text-grays-300 font-semibold">
            {{ val }}
            <AnnualCard :data="data"/>
          </div>
      </div>
  </div>
</template>

<script>
import AnnualCard from './AnnualCard.vue';
import axios from 'axios';

export default {
    components: {
        AnnualCard
    },
    data() {
        return {
            getDataTable: null
        }
    },
    mounted() {
        axios
        .get('https://api.dharmagroup.co.id/api/report/datatable/prospect', {
            'headers': { 'Authorization': 'Bearer 71063a7eab248181eafe665bf4ae346f-sFqsGThLIgEL9Kvjo0NZInsTou2NMoexYTnPwVjXAgV1K2scDuW58jBBy53'} })
        .then((res) => {
            // console.log(res);
            this.getDataTable = res.data.data         
        })
    }
}
</script>

<style>

</style>
<template>
    <div class="flex flex-col items-start"  :class="[position]">
        <div class="w-52">
            <div 
                class="my-2 p-1 flex border  rounded-sm cursor-pointer" 
                @click="activeDrop = !activeDrop"
                :class="[backgroundColor]"
            >
                <div class="flex flex-auto flex-wrap"></div>
                <span 
                    class="p-1 px-2 w-full text-left text-sm flex items-center"
                    :class="[textColor]"
                >
                   {{ activeTitle }}
                </span>
                <div class="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200">
                    <button class="cursor-pointer w-6 h-6 outline-none focus:outline-none" :class="[textColor]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-up w-4 h-4" :class="{'transY':activeDrop}">
                            <polyline points="18 15 12 9 6 15"></polyline>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <div 
            class="absolute shadow top-100 z-40 w-52 lef-0 rounded-sm max-h-select overflow-y-auto hidden" 
            :class="{'activeDrop' : activeDrop }"
        >
            <div class="flex flex-col w-full">
                <div class="w-full rounded-t border-b bg-white ">
                    <div 
                        v-for="(item, index) in dtar" :key="index" @click="handleSelect(item)"
                        class="flex w-full items-center p-2 pl-2 cursor-pointer mb-1 hover:bg-grays-100">
                        <div class="w-full items-center flex capitalize">
                            <div class="mx-2 text-sm ">{{item}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import EventBus from '@/eventBus'

export default {
    data() {
        return {
            activeDrop: false,
            activeTitle: this.title
        }
    },
    props:{
        dtar: {
            type: Array
        },
        title: {default: "Select Item", type: String},
        backgroundColor: { default: "bg-white border-secondary", type: String },
        textColor: { default: "text-secondary", type: String },
        position: { default: "relative", type: String },

    },
    methods: {
        handleSelect(item){
            this.activeDrop = false
            this.activeTitle = item
            EventBus.$emit('DATA_PUBLISHED', item)
            // this.$store.commit('karir/SET_COMPANY_ID', item.id)
        }
    },
}
</script>

<style>
    .top-100 {top: 100%}
    .bottom-100 {bottom: 100%}
    .max-h-select {
        max-height: 300px;
    }

    .activeDrop{
        display: block;
    }

    .transY{
        transform: rotate(180deg);
    }
</style>
<template>
  <div class="px-2 lg:px-0">
      <p class="mb-8 text-justify" v-html="hubinvestData.invest"></p>
      <div class="mt-12 flex flex-col justify-start items-start">
          <div v-for="data, i in getData" :key="i" class="">              
              <InvestDetail :investData="data" />
          </div>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import InvestDetail from './InvestDetail'

export default {
    components: {
        InvestDetail
    },
    props:[
        'hubinvestData',
        'lang'
    ],
    data() {
        return {
            seen: false,
        }
    },
    computed: {
        ...mapState({
            getData: state => state.home.home ? state.home.home.invest :''
        }),
    },
}
</script>

<style>

</style>
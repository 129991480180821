<template>
  <div class="px-2 lg:px-0">
      <div>
          <!-- <dropdown 
            :dtar="yearList"
            backgroundColor="bg-primary"
            textColor="text-grays-50 font-bold"
            class="mt-0"
            title="Semua"
          /> -->
          <div v-for="data, val in getDataTable" class="mb-2 text-2xl text-grays-300 font-semibold">
            {{ val }}
            <AnnualCard :data="data" />
          </div>
      </div>
  </div>
</template>

<script>
import AnnualCard from './AnnualCard.vue';
import dropdown from "@/components/DropdownKey"
import EventBus from '@/eventBus'
import axios from 'axios';

export default {
    components: {
        AnnualCard,
        dropdown
    },
    data() {
        return {
            getDataTable: "",
            yearList: [],
            whichYear: ""

        }
    },
    mounted() {
        axios
        .get('https://api.dharmagroup.co.id/api/report/datatable/yearly', {
            'headers': { 'Authorization': 'Bearer 71063a7eab248181eafe665bf4ae346f-sFqsGThLIgEL9Kvjo0NZInsTou2NMoexYTnPwVjXAgV1K2scDuW58jBBy53'} })
        .then((res) => {
            this.getDataTable = res.data.data   
            let rev = this.dict_reverse(res.data.data)
        })

        EventBus.$on('DATA_PUBLISHED', (payload) => {
            this.updateData(payload)
        })
    },
    computed: {
        sortData() {
            return this.getDataTable[this.whichYear]
        }
    },
    methods: {
        updateData(payload) {
            this.whichYear = payload
        },
        dict_reverse(obj) {
            const ordered = Object.keys(this.getDataTable).sort().reverse(
            (obj, key) => { 
                obj[key] = this.getDataTable[key]; 
                return obj;
            }, 
            {}
            );

            this.yearList = ordered
        }
    }
}
</script>

<style>

</style>
<template>
  <div class="hubinvest">
        <carousel
            :nav = true
            :centerMode = true
            :autoPlay = true
            :playSpeed = 8000
            :dtImg = banner
        />
        <div class="bg-grays-100 md:h-24 text-grays-400 w-full h-20 overflow-x-scroll">
            <ul class="flex justify-between items-center h-full lg:px-32">
                <li 
                    v-for="data, i in tabMenu" 
                    :key="i"
                    class="px-2 mx-3 capitalize font-pop text-xs lg:text-sm text-grays-400 font-semibold w-40 h-full flex items-center cursor-pointer duration-100 justify-center border-b-4 border-solid border-grays-200 hover:border-primary hover:text-primary flex-wrap"
                    :class="{'activeTab' : i == activeTab }" 
                    @click="tabNav(i)" 
                >
                    <div v-if="lang == 'id'">
                        {{ data.title }}
                    </div> 
                    <div v-else>
                        {{ data.title_eng }}
                    </div> 
                </li>
            </ul>
        </div>
        <div class="max-w-screen-xl mx-auto mt-12 mb-32 lg:px-32 px-2 text-left">
            <!-- <div class="my-6 px-2 lg:px-0" v-if="lang == 'id'">
                <h5 class="text-lg">{{ whichTab.caption }}</h5>
            </div>
            <div class="my-6 px-2 lg:px-0" v-else>
                <h5 class="text-lg">{{ whichTab.caption_eng }}</h5>
            </div> -->
            <Mengapa v-if="activeTab == 0" :hubinvestData="hubinvestData" :lang="lang" />
            <KinerjaPerusahaan v-if="activeTab == 1" />
            <LaporanTahunan v-if="activeTab == 2" :hubinvestData="hubinvestData" :lang="lang" />
            <InformasiSaham v-if="activeTab == 3" :hubinvestData="hubinvestData" :lang="lang" />
            <InformasiDeviden v-if="activeTab == 4" :hubinvestData="hubinvestData" :lang="lang" />
            <Presentasi v-if="activeTab == 5" />
            <Prospektus v-if="activeTab == 6" :hubinvestData="hubinvestData" :lang="lang" />
        </div>
  </div>
</template>

<script>
import carousel from "@/components/carousel.vue";
import Mengapa from "@/components/hubunganinvestor/Mengapa.vue";
import LaporanTahunan from "@/components/hubunganinvestor/LaporanTahunan.vue";
import InformasiSaham from "@/components/hubunganinvestor/InformasiSaham.vue";
import InformasiDeviden from "@/components/hubunganinvestor/InformasiDeviden.vue";
import Prospektus from "@/components/hubunganinvestor/Prospektus.vue";
import KinerjaPerusahaan from "@/components/hubunganinvestor/KinerjaPerusahaan.vue";
import Presentasi from "@/components/hubunganinvestor/Presentasi.vue";
import { mapState } from 'vuex';

export default {
    components: {
        Mengapa,
        LaporanTahunan,
        InformasiSaham,
        InformasiDeviden,
        Prospektus,
        KinerjaPerusahaan,
        Presentasi,
        carousel
    },
    data() {
        return {
            activeTab: 0,
            testNum: 0,
            tabMenu: [
                {
                    title: 'Mengapa Berinvestasi di PT Dharma Polimetal Tbk ?',
                    title_eng: 'Why Should Invest in PT Dharma Polimetal Tbk ?',
                    name: 'mengapa',
                    caption: 'Mengapa Berinvestasi di PT Dharma Polimetal Tbk ?',
                    caption_eng: 'Why Should Invest in PT Dharma Polimetal Tbk ?'
                },
                {
                    title: 'Kinerja Perusahaan',
                    title_eng: 'Company Performance',
                    name: 'kinerjaperusahaan',
                    caption: 'Ringkasan Keuangan dan Operasional PT Dharma Polimetal Tbk',
                    caption_eng: 'PT Dharma Polimetal Tbk Financial and Operational'
                },
                {
                    title: 'Laporan Tahunan',
                    title_eng: 'Annual Report',
                    name: 'laporantahunan',
                    caption: 'Ringkasan Keuangan PT Dharma Polimetal Tbk',
                    caption_eng: 'PT Dharma Polimetal Tbk Financial'
                },
                {
                    title: 'Informasi Pemegang Saham',
                    title_eng: 'Shareholders Information',
                    name: 'informasisaham',
                    caption: 'Informasi Pemegang Saham',
                    caption_eng: 'Shareholders Information'
                },
                {
                    title: 'Informasi Dividen',
                    title_eng: 'Dividen Information',
                    name: 'informasidividen',
                    caption: 'Informasi Dividen',
                    caption_eng: 'Dividen Information'
                },
                {
                    title: 'Presentasi',
                    title_eng: 'Presentation',
                    name: 'presentasi',
                    caption: 'Presentasi PT Dharma Polimetal Tbk',
                    caption_eng: 'PT Dharma Polimetal Tbk Presentation'
                },
                {
                    title: 'Prospektus',
                    title_eng: 'Prospect',
                    name: 'prospektus',
                    caption: 'Prospektus PT Dharma Polimetal Tbk',
                    caption_eng: 'PT Dharma Polimetal Tbk Prospectus'
                }
            ]
        }
    },
    created() {
        // console.log(this.$route.params.data);
        let routeParam = this.$route.params.data

        if (routeParam) {
            this.activeTab = routeParam
        } else {
            this.activeTab = 0
        }
        document.addEventListener('beforeunload', this.destroyTabParam)
    },
    methods: {
        tabNav(i) {
            // console.log(this.tabMenu[i]);
            this.activeTab = i
        },
        destroyTabParam() {
            this.activeTab = 0
            // console.log(destroyed);
        }
    },
    computed: {
        whichTab() {
            return this.tabMenu[this.activeTab]
        },
        ...mapState({
            data: state => state.home.home ? state.home.home.data :'',
            lang: state => state.home.lang ? state.home.lang :'id',
        }),
        hubinvestData(){
            let data_id = {}
            let data_eng= {}

            if (this.lang == 'id'){
				Object.assign(data_id, 
					{invest: this.data.invest},
					{commissioner: this.data.commissioner},
					{director: this.data.director},
					{dividend: this.data.dividend},
					{shareholder: this.data.shareholder},
					{fact: this.data.fact},
					{transparency: this.data.transparency},
                    {risk: this.data.risk},
					
				);
				return data_id
			}else{
				Object.assign(data_eng, 
					{invest: this.data.invest_eng},
					{commissioner: this.data.commissioner_eng},
					{director: this.data.director_eng},
					{dividend: this.data.dividend_eng},
					{shareholder: this.data.shareholder_eng},
					{fact: this.data.fact_eng},
					{transparency: this.data.transparency_eng},
                    {risk: this.data.risk_eng},
				);
				return data_eng
			}
        },
        banner(){
			return this.$store.getters['image/imgWtFilter']('investor_banner')
		}
    }
}
</script>

<style>
    .activeTab{
        @apply bg-primary text-white border-primary hover:text-grays-50;
    }
</style>